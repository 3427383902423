module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/kikico.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"once":true,"disable":false,"rootMargin":"0% 15%","selector":"[data-anim]","animateClassName":"is-inview","disabledClassName":"is-disabled"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
